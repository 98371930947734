<template>
  <div class="terms-of-use__wrapper">
    <div class="terms-of-use">
      <div class="terms-of-use__head-wrapper">
        <div class="terms-of-use__head">
          <div class="bold-600 fs-36 mb-3">
            Terms of use
          </div>
          <div class="bold fs-18">
            Effective Date: 15 May 2024 <br>
            For: SatoshiEye
          </div>
        </div>
      </div>

      <div class="terms-of-use__content-wrapper">
        <div class="terms-of-use__content">
          <div class="bold-600 fs-24 mb-3">
            Terms and Definitions
          </div>
          <p>For the purposes of these Terms of Service (hereinafter the "Terms"), the following terms shall have the meanings set forth below:</p>
          <ul>
            <li>"Confidential Information" means any and all information received or acquired by User from SatoshiEye in pursuance of these Terms, in whatever form, directly or indirectly, orally or in writing, that is designated as confidential or that reasonably expected to be confidential given the nature of the information and the circumstances of disclosure.</li>
            <li>"Services" means the SatoshiEye web application and, if applicable, the SatoshiEye API.</li>
            <li>"Updates" means any updates, upgrades, bug fixes, patches or other error corrections to the Services provided by SatoshiEye.</li>
            <li>"SatoshiEye" means a web application operating on the internet and accessible for use by means of web browsing software (such as Google Chrome, Microsoft Edge, Mozilla Firefox, or other compatible software, hereinafter "Browser") and, if applicable, the SatoshiEye API;</li>
            <li>"SatoshiEye Report" means the information provided to the Client concerning the AML risk assessment of blockchain addresses associated with virtual assets. The Report may come in various types, each contingent on its content. The content of each Report type may be subject to alteration at the sole discretion of the Provider. The Provider does not provide any explicit or implicit assurance that the report will align with the Client's requirements;</li>
            <li>"Client" or "you" or “user” means the legal entity/person that is purchasing access to the SatoshiEye service;</li>
            <li>"Device" means a computer or a mobile device owned or otherwise controlled by Client and/or Client's staff (each an "End User") where the Services are used by means of a Browser;</li>
            <li>"Intellectual Property Rights" means patents, copyrights, registered and unregistered design rights, trademarks, trade secrets, know-how, database rights, and all other similar or corresponding proprietary rights (whether registered or unregistered) and all applications for the same, anywhere in the world.</li>
          </ul>
          <div class="bold-600 fs-24 mb-3">
            Terms of Service
          </div>
          <p>These Terms constitute a legal agreement between Client ("you") and SatoshiEye (a product of GLO Services AG, incorporated and registered in Switzerland with company number CHF-440.055.156 whose registered office is at Grafenauweg 8, CH-6300 Zug, Switzerland) and governs your use of the Services accessible through a Browser, including any updates or supplements to the Services, subject to any additional terms that might apply to such updates or supplements. These Terms are made by providing Client's full and unconditional consent to conclude the Terms in full, without signing a written copy of the Terms by the Parties. These Terms have full legal force and effect and are equivalent to the Terms signed by the Parties in writing.</p>
          <p>The Services are protected by SatoshiEye's, or its or their respective licensors or service providers' Intellectual Property Rights.</p>
          <p>The terms of our website Privacy Policy and Cookie Policy which are available at <a href="https://satoshieye.com">https://satoshieye.com</a>, are incorporated into these Terms by reference.</p>
          <p>These Terms represent the entire agreement concerning the Services between you and SatoshiEye, and it supersedes any prior proposal, representation, or understanding between the parties.</p>
          <p>By agreeing and/or using the Services you (a) acknowledge that you have read and understood these Terms; and (b) accept these Terms and agree that you are legally bound by its terms and automatically agree for prices for Services and all annexes that are integral parts of these Terms.</p>
          <p>If you do not agree to these terms, do not use the Services.</p>
          <p>SatoshiEye reserves the right, at its sole discretion, to modify these Terms, at any time and without prior notice. If SatoshiEye modifies these Terms, SatoshiEye will post the modification at <a href="https://satoshieye.com">https://satoshieye.com</a>. SatoshiEye will endeavour to notify you of any changes by email but will not be liable for any failure to do so. SatoshiEye will also update the "Effective Date" at the top of these Terms.</p>
          <p>By continuing to access or use the Services after SatoshiEye has posted a modification on the website or provided you with notice of a modification, you are indicating that you agree to be bound by the modified Terms. If the modified Terms are not acceptable to you, please do not access or use the Services.</p>
          <div class="bold-600 fs-24 mb-3">
            Access and Use of Services
          </div>
          <p>Subject to the terms of these Terms and your top-up payments in accordance with the Payment Terms section of these Terms, SatoshiEye grants you a limited, non-exclusive, non-transferable, and revocable right to access and use the Services strictly in accordance with the Services' documentation and these Terms.</p>
          <p>You may access the Services and view, use, and display the Services on your Device for your personal use or internal business purposes only (which shall not include the use of the Services by, or for the benefit of, any person other than you or your business).</p>
          <div class="bold-600 fs-24 mb-3">
            Payment Terms
          </div>
          <p>1. The Client is required to make top-up payments for the services provided by SatoshiEye, equivalent to the agreed-upon amount indicated in the Online Service.<br>
            2. All amounts and fees stated or referred to in the corresponding agreement are exclusive of any applicable taxes. The Client shall pay the billed amount in full without any offset, deduction, or withholding whatsoever, including for or on account of any taxes or other duties. If the Client is required by law to withhold any tax from the sums due to SatoshiEye, the liability for such withholding shall be borne entirely by the Client.<br>
            3. The method of payment is Online payment via SatoshiEye's Platform Online Service, including through self-service checkout.<br>
            4. All amounts shall be expressed and made in the Contractual Currency, which is the Euro, unless otherwise specified.<br>
            5. Top-up payments may define payment in virtual currencies. In such cases, the Euro shall be the Contractual Currency for expressing amounts and billing. The Euro equivalent shall be calculated based on fair value estimated by the Provider.</p>
          <div class="bold-600 fs-24 mb-3">
            Purchasing and Usage of Lookups
          </div>
          <p>Lookups are SatoshiEye's synthetic tokens, used as currency within the SatoshiEye application to purchase reports. Lookups hold no monetary value outside of the SatoshiEye application and cannot be exchanged, transferred, or withdrawn.</p>
          <p>Lookups can be purchased through predefined packages as listed on the SatoshiEye platform. All packages are priced in EUR, and users are not permitted to purchase custom amounts of Lookups not specified in the available packages.</p>
          <div class="bold-600 fs-24 mb-3">
            Reports
          </div>
          <p>SatoshiEye offers four types of escalating reports: Score, Types, Entities, and Full Report. Each subsequent report type includes all information provided in the prior reports, adding further detail and analysis.</p>
          <p>The cost of each report type is denoted in Lookups, and users must have sufficient Lookups in their account to purchase these reports.</p>
          <p>Users may update the data in their purchased reports at no additional cost to ensure they reflect the most current information available.</p>
          <div class="bold-600 fs-24 mb-3">
            Report Validity and Refresh
          </div>
          <p>Once purchased, reports are accessible to the user for an indefinite period within the SatoshiEye platform. Users can request a data refresh of any report to include the latest available data, maintaining the accuracy and relevance of the information.</p>
          <p>The system will verify the availability of new data before processing a refresh request to ensure significant updates are present.</p>
          <div class="bold-600 fs-24 mb-3">
            Restrictions on Use
          </div>
          <p>Except as expressly set out in these Terms, or as permitted by applicable local law, you shall not:</p>
          <ul>
            <li>modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Services;</li>
            <li>reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Services or any part thereof;</li>
            <li>remove, delete, alter or obscure any trademarks or any copyright, patent or other Intellectual Property Rights or proprietary rights notices from the Services, including any copy thereof;</li>
            <li>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Services or any features or functionality of the Services, to any third party for any reason, including by making the Services available on a network where it is capable of being accessed by more than one computer or device at any time, or for any services which may benefit the third-party business;</li>
            <li>take any action that imposes an unreasonable or disproportionately large load on the Services;</li>
            <li>facilitate any viruses, trojan horses, malware, worms or other computer programming routines that attempt to or may damage, disrupt, corrupt, misuse, detrimentally interfere with, surreptitiously intercept or expropriate, or gain unauthorized access to any system, data or information related to the Services;</li>
            <li>remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management, or security features in or protecting the Services;</li>
            <li>use the Services in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the Services or any operating system;</li>
            <li>infringe SatoshiEye's Intellectual Property Rights or those of any third party in relation to your use of the Services, including by the submission of any infringing material;</li>
            <li>transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Services;</li>
            <li>use the Services in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;</li>
            <li>collect or harvest any information or data from the Services or our systems or attempt to decipher any transmissions to or from the servers running any Service;</li>
            <li>use automated queries, data scraping, or any other means to access, collect, or extract data from the Services in a manner that exceeds normal usage or places an unreasonable burden on the Services' infrastructure.</li>
          </ul>
          <div class="bold-600 fs-24 mb-3">
            Intellectual Property Ownership
          </div>
          <p>The Services and all worldwide Intellectual Property Rights therein, are the exclusive property of SatoshiEye and its licensors. All rights in and to the Services not expressly granted to you in these Terms are reserved by SatoshiEye and its licensors. You agree that you neither own nor acquire any rights in the Services not expressly granted in these Terms. SatoshiEye and its licensors retain all right, title, and interest in the Services and all derivative works thereof, including all related Intellectual Property Rights.</p>
          <p>You acknowledge that you have no right to have access to the Services in source-code form.</p>
          <p>In submitting or transmitting any material using the Services you grant us a transferable, sub-licensable, non-exclusive, royalty-free, worldwide, irrevocable license to use such material in connection with the Services.</p>
          <div class="bold-600 fs-24 mb-3">
            Third-Party Services
          </div>
          <p>The Services may integrate with, rely on, or enable access to third-party websites, services, or platforms (collectively, "Third-Party Services"). SatoshiEye does not control any Third-Party Services and shall not be responsible for the content, functionality, availability, or security of such Third-Party Services. Your use of and interaction with any Third-Party Services, including through the Services, is at your own risk and is subject to the terms, conditions, and policies of the applicable Third-Party Services. SatoshiEye is not responsible for any losses or damages incurred as a result of your use of or reliance on any Third-Party Services.</p>
          <div class="bold-600 fs-24 mb-3">
            Updates
          </div>
          <p>SatoshiEye may from time to time in its sole discretion develop and provide updates, upgrades, and changes to the Services, which may include upgrades, bug fixes, patches and other error corrections and/or new features (collectively, including related documentation, "Updates"). Updates may also modify or delete in their entirety certain features and functionality.</p>
          <p>You agree that SatoshiEye has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality.</p>
          <div class="bold-600 fs-24 mb-3">
            Disclaimer of Warranties
          </div>
          <p>The Services are provided to you "as is" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, SatoshiEye, on its own behalf and its licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Services, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, SatoshiEye provides no warranty or undertaking, and makes no representation of any kind that the Services will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
          <div class="bold-600 fs-24 mb-3">
            Limitation of Liability
          </div>
          <p>To the maximum extent permitted by applicable law, in no event shall SatoshiEye's aggregate liability arising out of or related to these Terms, whether arising out of or related to breach of contract, tort (including negligence), or otherwise, exceed the total amounts paid to SatoshiEye pursuant to these Terms in the twelve (12) month period preceding the event giving rise to the claim.</p>
          <p>In no event shall SatoshiEye have any liability to the other party for any lost profits, revenues, or indirect, special, incidental, consequential, cover, or punitive damages, whether an action is in contract or tort and regardless of the theory of liability, even if a party has been advised of the possibility of such damages. The foregoing disclaimer will not apply to the extent prohibited by applicable law.</p>
          <p>The foregoing limitations:</p>
          <ul>
            <li>will apply whether such damages arise out of breach of contract, tort (including negligence) or otherwise and regardless of whether such damages were foreseeable or SatoshiEye was advised of the possibility of such damages; and</li>
            <li>include any loss caused by the failure of the Services to correctly identify participants in blockchain transactions or the levels of any associated risks such as fraudulent activity, and you acknowledge and agree that in entering into these Terms you do not rely on the Services for such purposes.</li>
          </ul>
          <div class="bold-600 fs-24 mb-3">
            Indemnification
          </div>
          <p>User agrees to indemnify, defend and hold harmless SatoshiEye and its officers, directors, employees, agents, contractors, affiliates, successors and assigns from and against any and all third-party claims, damages, liabilities, costs, and expenses, including reasonable attorneys' fees, arising from or relating to (i) User's breach of these Terms; or (ii) User's use of the Services in violation of applicable law. Furthermore, you agree that SatoshiEye assumes no responsibility for the content you submit or make available through the Services.</p>
          <p>In the event of any claim, action, or proceeding brought against SatoshiEye related to your use of the Services or your breach of these Terms, you agree to cooperate fully with SatoshiEye in the defense of such claim, action, or proceeding. SatoshiEye reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you.</p>
          <div class="bold-600 fs-24 mb-3">
            Confidentiality
          </div>
          <p>"Confidential Information" includes any and all information received or acquired by you from SatoshiEye in pursuance of these Terms, in whatever form, before or after the effective date hereof, directly or indirectly, orally, in any written form, or in any magnetically or electronically recorded form or another form, whether or not stored, compiled or memorialized in any media or in writing, including, without limitation, information and materials pertaining to party's business, technologies, codes, source codes, products, intellectual property, developments, finances, operations, processes, regulatory information, analysis, ideas, concepts, trade secrets, know-how, technical information, inventions, business plans, products, samples, product information, research, drawings, algorithms, software codes and designs, physical material, customers, employees and consultants, contractors, suppliers, marketing plans, and other proprietary and commercial information, shall not be disclosed to any third party by the recipient except as provided in these Terms or any other written agreement between the parties.</p>
          <p>You shall use a reasonable degree of care to maintain all Confidential Information in confidence and shall not disclose to any third-party nor use Confidential Information for any unauthorised purpose. You may only disclose Confidential Information to those of Your employees and representatives that have both (i) a need to know for your internal purposes in configuring, installing, using, and supporting the Services and (ii) are legally bound by confidentiality obligations no less stringent than those of this Agreement.</p>
          <p>The provisions of this clause shall not apply to any information which:</p>
          <p>(a) is in, or later comes into, the public domain otherwise than by breach of these Terms or any other agreement between the parties;<br>
            (b) was demonstrably in the possession of the recipient prior to its receipt from the other party;<br>
            (c) is independently received from a third party who is free from any obligations to a third party not to disclose it;<br>
            (d) is demonstrably conceived by the recipient independently of the information received or acquired from the other party; or<br>
            (e) the recipient is bound by applicable laws or regulations to disclose.</p>
          <p>The provisions of this clause shall survive termination of these Terms for any reason.</p>
          <div class="bold-600 fs-24 mb-3">
            Term and Termination
          </div>
          <p>These Terms shall remain in effect until terminated as set forth in this section.</p>
          <p>SatoshiEye may terminate these Terms at any time with reasonable advance notice for any reason including if SatoshiEye ceases to support the Services, which SatoshiEye may do at its sole discretion. Other reasons for termination include: (i) an allegation or actual infringement of any Intellectual Property Right; (ii) an allegation of or actual defamation; (iii) an allegation or determination that the Services do not comply with applicable law; (iv) SatoshiEye ceasing to do business; or (v) SatoshiEye filing a petition in bankruptcy, dissolving, or otherwise finding itself unable to pay its debts as they come due. In the event of a termination by SatoshiEye under this subsection, SatoshiEye shall refund you a pro-rata portion of the pre-paid fees attributable to the remainder of the term of the applicable order form.</p>
          <p>SatoshiEye may terminate these Terms immediately without any notice if you breach any of the terms and conditions of these Terms.</p>
          <p>A Service may be removed from the SatoshiEye website at any time if it is determined that the Service (i) may infringe or otherwise infringe the Intellectual Property Rights or any other rights of any third party; (ii) violates any applicable law, regulation; or (iii) is subject to an injunction.</p>
          <p>Upon any termination of these Terms: (i) all rights granted to User under these Terms shall immediately terminate; (ii) User shall cease all use of the Services and destroy any copies of the Services in its possession; and (iii) any outstanding Lookups in User's account shall be forfeited without refund. The following sections shall survive any termination of these Terms: [Intellectual Property Ownership, Confidentiality, Limitation of Liability, Indemnification, Governing Law, and Miscellaneous].</p>
          <p>Termination will not limit any of SatoshiEye's rights or remedies at law or in equity.</p>
          <div class="bold-600 fs-24 mb-3">
            Communication Between Us
          </div>
          <p>If you wish to contact SatoshiEye in writing, or if any condition in these Terms requires you to give us notice in writing, you can send this to us by e-mail to <a href="mailto:support@satoshieye.com">support@satoshieye.com</a>.</p>
          <div class="bold-600 fs-24 mb-3">
            Miscellaneous
          </div>
          <p>1. Transfer<br>SatoshiEye may transfer our rights and obligations under these Terms to another organization in case of a merger or acquisition with such another organization. You may only transfer your rights or obligations under these Terms upon SatoshiEye's prior written consent.</p>
          <p>2. Severability<br>If any provision of these Terms is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term, and all other provisions of these Terms will continue in full force and effect.</p>
          <p>3. Governing Law & Jurisdiction<br>The Terms and any disputes or claims arising out of or in connection with it or its subject matter or formation (including without limitation disputes or claims) are governed by and construed in accordance with the laws of Switzerland. Any dispute, controversy, or claim arising out of or relating to the Terms, or the breach, termination, or invalidity thereof, shall be referred to and finally resolved by an Arbitration tribunal in accordance with the Swiss Rules of International Arbitration of the Swiss Arbitration Centre in force on the date on which the Notice of Arbitration is submitted in accordance with those Rules (the "Swiss Rules"). The seat of the arbitration shall be Zug, Switzerland (unless the parties agree in writing on a city in another country); The arbitral proceedings shall be conducted in English language before one (1) arbitrator appointed in accordance with the Swiss Rules.</p>
          <p>4. Entire Agreement<br>These Terms constitute the entire agreement between you and SatoshiEye with respect to the Services and supersedes all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Services.</p>
          <p>5. Waiver<br>No failure to exercise and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between these Terms and any applicable purchase or other terms, the terms of these Terms shall govern.</p>
          <p>6. Export Regulation<br>The Services may be subject to the laws related to export control, economic or financial sanctions and/or trade embargoes of the including but not limited to the US Export Administration Act. Without limiting the generality of the foregoing, you shall not, directly or indirectly, export, re-export, or release the Services to, or make the Services accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by applicable law, rule, or regulation. You shall comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval) and you shall not export, re-export, release, or otherwise make the Services available, without obtaining all required governmental licenses or other authorizations.</p>
          <p>7. Force Majeure<br>Notwithstanding any other provision of these Terms, SatoshiEye shall not be deemed in default of these Terms for failure to fulfil its obligations when due to causes beyond its reasonable control.</p>
          <p>8. Collection of Information<br>You acknowledge that when you use the Services, SatoshiEye may use automatic means (including, for example, cookies) to collect information about your device and about your use of the Services. You also may be required to provide certain information about yourself as a condition to use certain features or functionality of the Services. All information we collect through or in connection with the Services is subject to our Privacy Policy. By using, and providing information to or through the Services, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
}
</script>
